<template>
  <div class="nav">
    <el-popover
      class="nav_son"
      placement="bottom"
      trigger="click"
      v-model="visible"
    >
      <div class="st_box">
        <div v-if="num > 0">
          <ul>
            <li
              class="clearfix"
              v-for="(item, index) in basketlist"
              :key="index"
            >
              <span class="fl timu">{{ index }}</span>
              <el-progress
                class="fl"
                :percentage="(item.length / num) * 100"
                :format="format"
              ></el-progress>
              <span class="fl sc" @click="shanchu(index)">删除</span>
            </li>
            <li class="clearfix">
              <p class="fl">平均难度 : <span>{{difficulty}}</span></p>
              <p class="fr">共计{{ num }}题</p>
            </li>
          </ul>
          <div class="clearfix caozuo">
            <p class="fl" @click="qingkong">
              <i class="el-icon-delete"></i> 清空全部
            </p>
            <p class="fr" @click="handlePaper">进入组卷中心</p>
          </div>
        </div>
        <div v-else style="text-align: center; line-height: 40px">
          快去选择你想要的试题吧
        </div>
      </div>
      <el-button slot="reference" round>试题篮 <span v-if="num > 0">({{ num }})</span></el-button>
    </el-popover>
  </div>
</template>


<script>
import VueEvent from "@/api/eventBus.js";
import axios from "axios";
export default {
  data() {
    return {
      basketlist: [],
      difficulty:'',
      num: -1,
      visible: false,
      formatypearr:[ 
          {"fmttype_name": "全部","fmttype_id" :'0'},{"fmttype_name": "容易","fmttype_id" :'1'},{"fmttype_name": "较易","fmttype_id" :'2'},
          {"fmttype_name": "一般","fmttype_id" :'3'},{"fmttype_name": "较难","fmttype_id" :'4'},{"fmttype_name": "困难","fmttype_id" :'5'},
      ],
      
      // 数据上报字段
      client_ip:"",
    };
  },
  created() {
    if (JSON.parse(localStorage.getItem("shitilength"))) {
      this.num = JSON.parse(localStorage.getItem("shitilength"));
    }
    if (JSON.parse(localStorage.getItem("shiti"))) {
      this.basketlist = JSON.parse(localStorage.getItem("shiti"));
    }
  },
  mounted() {
    var that = this;
    VueEvent.$off("shitilength");
    VueEvent.$on("shitilength", function (data) {
      that.shuaxin(data);
    });
    VueEvent.$off("basketlist");
    VueEvent.$on("basketlist", function (data) {
      that.shuaxin(data);
    });
  },
  watch: {
    basketlist(val,old){
      if(val != null){
        var that = this
        var tixing = Object.keys(val)
        var zongshu = ''
        tixing.forEach(function(res){
          val[res].forEach(function(res1){
            that.formatypearr.forEach(function(res2){
              if(res2.fmttype_name == res1.question_level){
                zongshu = Number(zongshu) + Number(res2.fmttype_id)
              }
            })
          })
        })
        that.formatypearr.forEach(function(res3){
          if(res3.fmttype_id == Math.round(Number(zongshu)/Number(JSON.parse(localStorage.getItem("shitilength"))))){
            that.difficulty = res3.fmttype_name
          }
        })
      }
    }
  },
  methods: {
    shuaxin(e) {
      this.num = e.a;
      this.basketlist = e.b;
    },
    // 试题篮题数
    format(percentage) {
      var i = this.num;
      return percentage == percentage
        ? `${Math.round((percentage / 100) * i)}题`
        : "";
    },
    //删除
    shanchu(val) {
      // 试题篮删除大题，试卷记录
      var listtitle = JSON.parse(localStorage.getItem("listtitle"));
      if (listtitle) {
        for (var i = 0; i < listtitle.length; i++) {
          if (listtitle[i] == val) {
            listtitle.splice(i, 1);
            localStorage.setItem("listtitle", JSON.stringify(listtitle));
          }
        }
      }
      VueEvent.$emit("huifu", true);
      this.num = this.num - this.basketlist[val].length;
      delete this.basketlist[val];
      if (Object.keys(this.basketlist).length == 0) {
        this.visible = false;
        this.num = -1;
        VueEvent.$emit("syshiti", this.basketlist);
        localStorage.removeItem("shiti");
        localStorage.removeItem("shitilength");
      } else {
        VueEvent.$emit("syshiti", this.basketlist);
        localStorage.setItem("shiti", JSON.stringify(this.basketlist));
        localStorage.setItem(
          "shitilength",
          JSON.stringify(Object.keys(this.basketlist).length)
        );
      }
    },
    //清空
    qingkong() {
      this.num = -1;
      this.visible = false;
      localStorage.removeItem("shiti");
      localStorage.removeItem("shitilength");
      localStorage.removeItem("listtitle");
      this.basketlist = [];
      VueEvent.$emit("syshiti", this.basketlist);
      VueEvent.$emit("huifu", true);
    },
    handlePaper() {
      // this.entrancework()
      this.$router.push("/testPaper/examPaper");
      localStorage.removeItem("pages");
    },

    // 数据上报
    // 进入组卷中心
    entrancework(){
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
        
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_centreentrance",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "进入了组卷的组卷中心",
          data: {
            school_name: iteminfo.teacher_info.school_name
            ? `${iteminfo.teacher_info.school_name}`
            : "",
            school_id: iteminfo.teacher_info.school_id
            ? `${iteminfo.teacher_info.school_id}`
            : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
          },
        }
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
      })
    }
  },
};
</script>

<style lang="less" scoped>
.nav_son {
  position: absolute;
  right: 40px;
  top: -3px;
  z-index: 3;
  & /deep/ .el-button {
    background-color: #409EFF;
    padding: 8px 15px;
    border: none;
    // line-height: 40px;
    font-size: 16px;
    color: #fff;
  }
}
.nav_botton {
  flex: 1;
  background: #f1f3f7;
}
.st_box {
  width: 300px;
  max-height: 300px;
  .caozuo {
    font-size: 12px;
    border-top: 1px solid #ececec;
    p {
      line-height: 30px;
      margin: 10px;
      cursor: pointer;
      &:first-child {
        color: #3587fc;
      }
      &:nth-child(2) {
        background-color: #409eff;
        color: #fff;
        padding: 0 10px;
        border-radius: 15px;
      }
    }
  }
  ul {
    li {
      font-size: 14px;
      line-height: 30px;
      .el-progress {
        display: inline-block;
        width: 60%;
        padding: 7px 10px 0;
        & /deep/ .el-progress__text {
          font-size: 14px !important;
        }
      }
      .sc {
        color: #3587fc;
        cursor: pointer;
      }
      &:last-child {
        font-size: 14px;
        line-height: 30px;
      }
      .timu {
        width: 5em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
