<template>
  <div class="clearfix navbar">
    <!-- <div class="menu">
      <span
        @click="handleRouter(item, index)"
        :class="{ newkey: index == index1 }"
        v-for="(item, index) in list"
        :key="index"
      >
        {{ item.text }}
      </span>
      <open v-if="index1 != 4"></open>
    </div> -->
    <el-menu
      :default-active="this.$route.path"
      @select="handleSelect"
      router
      mode="horizontal"
      active-text-color="#0e9cf6"
    >
      <el-menu-item v-for="(item, i) in list" :key="i" :index="item.name">{{
        item.text
      }}</el-menu-item>
    </el-menu>
    <div class="basket">
      <open v-show="this.index1 != this.list[4].name"></open>
    </div>
  </div>
</template>

<script>
import open from "./basket";
export default {
  data() {
    return {
      list: [
        { text: "章节选题", name: "/testPaper/ChapterTopics" },
        { text: "知识点选题", name: "/testPaper/KnowledgTopics" },
        { text: "智能组卷", name: "/testPaper/SmartTopics" },
        { text: "收藏选题", name: "/testPaper/FavoriteTopics" },
        { text: "我的", name: "/testPaper/PersonalTopics" },
      ],
      index1: "",
    };
  },
  components: {
    open,
  },
  created() {
    if (JSON.parse(window.localStorage.getItem("zjpages"))) {
      this.index1 = JSON.parse(window.localStorage.getItem("zjpages"));
    } else {
      this.index1 = "/testPaper/ChapterTopics";
    }
  },
  watch: {},
  mounted() {},
  methods: {
    handleSelect(index) {
      if (this.index1 == index) {
      } else {
        localStorage.removeItem("shiti");
        localStorage.removeItem("shitilength");
        localStorage.removeItem("centre");
      }
      this.index1 = index;
      localStorage.setItem("zjpages", JSON.stringify(this.index1));
    },
  },
};
</script>

<style lang='less' scoped>
.navbar {
  position: relative;
}
.el-menu {
  margin-top: 20px;
  // background-color: #5097fe;
  .el-menu-item {
    color: #000;
    font-size: 18px;
  }
  .el-menu-item:hover {
    color: #5097fe;
    // background-color: #5097fe;
  }
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #5097fe !important;
  // background-color: #6fb0f1;
  // border-bottom: none;
}
.basket {
  position: absolute;
  right: 0;
  top: 16px;
}
</style>
